import { useState, useEffect, useRef } from 'react';

// Global throttling controls
let scheduledFetches = 0;
const MAX_CONCURRENT_FETCHES = 50;
const MAX_RETRIES = 500000000;
const FETCH_TIMEOUT = 30000; // 30 seconds timeout

// Keep track of pending fetches for debugging
const pendingFetchTimers = new Map();

interface UseFetchSchedulerOptions {
  priority?: number;      // Lower number = higher priority
  maxConcurrent?: number; // Override default concurrent limit
  baseDelay?: number;     // Base delay in ms
  retryDelay?: number;    // Delay before retry if at concurrent limit
  maxRetries?: number;    // Maximum number of retry attempts
  timeout?: number;       // Timeout in ms before auto-completing
  debug?: boolean;        // Enable debug logging
}

/**
 * Custom hook for scheduling staggered data fetching
 * @returns [shouldFetch, markFetchCompleted]
 */
export function useFetchScheduler(options: UseFetchSchedulerOptions = {}) {
  const [shouldFetch, setShouldFetch] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const fetchIdRef = useRef<string>(`fetch_${Math.random().toString(36).substr(2, 9)}`);
  const fetchTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const {
    priority = 0,
    maxConcurrent = MAX_CONCURRENT_FETCHES,
    baseDelay = 50,
    retryDelay = 100,
    maxRetries = MAX_RETRIES,
    timeout = FETCH_TIMEOUT,
    debug = false
  } = options;

  // Schedule fetch based on priority and concurrency limits
  useEffect(() => {
    if (shouldFetch) return; // Already scheduled
    if (retryCount >= maxRetries) {
      debug && console.warn(`[FetchScheduler] Maximum retries (${maxRetries}) reached for ${fetchIdRef.current}`);
      return; // Give up after max retries
    }

    const priorityDelay = priority * 20;
    const delay = baseDelay + priorityDelay + (retryCount * retryDelay);

    const timer = setTimeout(() => {
      // Only schedule if we're under the concurrent limit
      if (scheduledFetches < maxConcurrent) {
        scheduledFetches++;
        setShouldFetch(true);

        // Set a timeout to auto-complete if fetch gets stuck
        fetchTimeoutRef.current = setTimeout(() => {
          debug && console.warn(`[FetchScheduler] Fetch ${fetchIdRef.current} timed out after ${timeout}ms`);
          markFetchCompleted();
        }, timeout);

        pendingFetchTimers.set(fetchIdRef.current, fetchTimeoutRef.current);
        debug && console.log(`[FetchScheduler] Started fetch ${fetchIdRef.current}, active: ${scheduledFetches}/${maxConcurrent}`);
      } else {
        // If we're at the limit, increase retry count
        setRetryCount(prev => prev + 1);
        debug && console.log(`[FetchScheduler] Retry ${retryCount + 1}/${maxRetries} for ${fetchIdRef.current}`);
      }
    }, delay);

    return () => {
      clearTimeout(timer);
      if (fetchTimeoutRef.current) {
        clearTimeout(fetchTimeoutRef.current);
        pendingFetchTimers.delete(fetchIdRef.current);
      }
    };
  }, [shouldFetch, priority, maxConcurrent, baseDelay, retryDelay, maxRetries, retryCount, timeout, debug]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (shouldFetch && !pendingFetchTimers.has(fetchIdRef.current)) {
        // If unmounting while fetch is in progress but no timer exists
        debug && console.log(`[FetchScheduler] Cleaning up on unmount for ${fetchIdRef.current}`);
        scheduledFetches = Math.max(0, scheduledFetches - 1);
      }

      if (fetchTimeoutRef.current) {
        clearTimeout(fetchTimeoutRef.current);
        pendingFetchTimers.delete(fetchIdRef.current);
      }
    };
  }, [shouldFetch, debug]);

  // Function to mark fetch as completed
  const markFetchCompleted = () => {
    if (!shouldFetch) return; // Don't decrement if we never started

    if (fetchTimeoutRef.current) {
      clearTimeout(fetchTimeoutRef.current);
      fetchTimeoutRef.current = null;
      pendingFetchTimers.delete(fetchIdRef.current);
    }

    scheduledFetches = Math.max(0, scheduledFetches - 1);
    debug && console.log(`[FetchScheduler] Completed fetch ${fetchIdRef.current}, remaining: ${scheduledFetches}/${maxConcurrent}`);
  };

  // Add diagnostic method to global window object for debugging
  useEffect(() => {
    if (debug && typeof window !== 'undefined') {
      (window as any).getFetchSchedulerStatus = () => {
        return {
          activeFetches: scheduledFetches,
          maxConcurrent: maxConcurrent,
          pendingTimers: Array.from(pendingFetchTimers.keys())
        };
      };
    }
  }, [debug, maxConcurrent]);

  return [shouldFetch, markFetchCompleted] as const;
}
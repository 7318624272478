import React, { useEffect, useState } from 'react';
import './Table.css';
import { TBlockchain } from '../../Blockchains';
import Web3 from 'web3';
import { Contract } from '../../config/contracts/contractSet';
import { getContractMetadata, TContractMetadata } from '../../utils/Contracts/ContractMetadata';
import { ContractName } from '../../config/contracts/types';
import { Emoji } from '../Design/Emoji';
import { HightlistedString } from '../Design/HightlightedString';

//
const mpcAccounts = [
    '0x7c056C04ae48EA56d20AF217e129d6e6C10A2bc4',
    '0x8Db4cb966E6C6FEFBac5B8259e23dd372dffC992'
]

function getClassName(result: TContractMetadata) {
    // Ensure PAO is also an admin role
    if (typeof result.proxyAdminOwnerIsAdmin === 'boolean') {
        if (!result.proxyAdminOwnerIsAdmin) {
            return 'table-danger'
        }
    }

    // Ensure PAO is a multisig with 5+ threshold
    const { proxyAdmin } = result;
    if (!proxyAdmin.safeParams) {
        if (mpcAccounts.includes(proxyAdmin.owner))
            return 'table-warning'

        return 'table-danger'
    }

    if (proxyAdmin.safeParams?.threshold < 5)
        return 'table-warning'

    return 'table-success'
}

// Simple FNV-1a hash function for strings
function hashString(str: string): number {
    let hash = 0x811c9dc5;
    for (let i = 0; i < str.length; i++) {
        hash ^= str.charCodeAt(i);
        hash += (hash << 1) + (hash << 4) + (hash << 7) + (hash << 8) + (hash << 24);
    }
    return hash >>> 0; // Ensure the result is a positive number
}

// Function to convert a number to a HEX color
function numberToHexColor(num: number): string {
    const r = (num & 0xFF0000) >> 16;
    const g = (num & 0x00FF00) >> 8;
    const b = num & 0x0000FF;
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}

// Function to generate a reproducible HEX color based on a string input
function generateHexColor(input: string): string {
    const hash = hashString(input);
    return numberToHexColor(hash);
}

function PAO({blockchain, contractData}: {blockchain: TBlockchain, contractData: TContractMetadata}) {
    return (<>
        <a href={blockchain.explorer.addr(contractData.proxyAdmin.owner)}>
            <HightlistedString s={contractData.proxyAdmin.owner.slice(0, 8)}/>
        </a>
        {contractData.proxyAdmin.safeParams ? `🔒${contractData.proxyAdmin.safeParams.threshold}/${contractData.proxyAdmin.safeParams.ownersCount}` : '🔑'}
    </>)
}

export function ContractVersion({ blockchain, contract }: { blockchain: TBlockchain, contract: Contract<ContractName> }) {
    const [contractData, setContractData] = useState<TContractMetadata | { error: Error } | undefined>();

    if (undefined === contractData) {
        getContractMetadata(contract)
            .then(data => {
                setContractData(data)
            }, (err) => {
                console.error('Error getting contract metadata', err)
                setContractData({ error :err })
            })
        return <td>👀</td>;
    }

    if ("error" in contractData) {
        if (contractData.error.message === 'Not a contract') {
            return (<td>
                😨
                <div><small>Not a <a href={blockchain.explorer.addr(contract.address)}>contract</a></small></div>
            </td>)
        }
        return (<td>
            😨
            <div><small>{String(contractData.error)}</small></div>
        </td>)
    }

    return (<td className={getClassName(contractData)}>
        <div><a href={blockchain.explorer.addr(contract.address)}>
            v<HightlistedString s={contractData.version}/>
        </a></div>
        <div style={{fontSize: '0.7em'}}>
            <table>
                <tr>
                    <td>PAO</td>
                    <td>
                        <PAO blockchain={blockchain} contractData={contractData}/>
                    </td>
                </tr>
                <tr>
                    <td>ADM?</td>
                    <td>{typeof contractData.proxyAdminOwnerIsAdmin === 'boolean' ? (contractData.proxyAdminOwnerIsAdmin ? <Emoji emoji={'✔️'}/> : "🛑 NO") : 'N/A'}</td>
                </tr>
                <tr>
                    <td>IMPL</td>
                    <td>
                        <a href={blockchain.explorer.addr(contractData.contract.implementation)} style={{
                            color: generateHexColor(contract.contractName + contractData.contract.implementationBytecode),
                        }}>
                            <HightlistedString s={Web3.utils.keccak256((contractData.contract.implementationBytecode)).slice(0, 8)} />
                        </a>
                    </td>
                </tr>
            </table>
        </div>
    </td>)
}
import './Table.css';
import { contractNames } from '../../config/contracts/types';
import { BlockchainRow } from './BlockchainRow';
import { useGetBlockchains } from '../../contexts/GetBlockchains';

function Table() {
  const blockchains = useGetBlockchains();

  return (
    <table className='table table-bordered table-hover border-primary table-responsive-sm fees-table'>
        <thead>
            <tr>
                <th>Network</th>
                <th>DeBridgeGate</th>
                <th>DeBridgeGate</th>
                <th>DlnSource</th>
                <th>DlnSource</th>
            </tr>
        </thead>
        <tbody>
              {blockchains.map((blockchain, key) =>
                    <BlockchainRow blockchain={blockchain} key={key} />
                )}
        </tbody>
    </table>
  );
}

export default Table;

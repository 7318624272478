import './Table.css';
import { useGetBlockchains } from '../../contexts/GetBlockchains';
import { Channel } from './Channel';

function Table() {
  const blockchains = useGetBlockchains();

  return (
    <table className='table table-dark table-bordered table-hover border-primary channels-table'>
        <thead>
            <tr>
                <th></th>
                { blockchains.map((rightBlockchain, key) => <th key={key}>{rightBlockchain.name}</th>) }
            </tr>
        </thead>
        <tbody>
                {blockchains.map((leftBlockchain, key) =>
                    <tr key={key}>
                        <td>{leftBlockchain.name}</td>
                        {blockchains.map((rightBlockchain, key) =>
                            <Channel key={key} left={leftBlockchain} right={rightBlockchain} priority={key}/>

                        )}
                    </tr>
                )}
        </tbody>
    </table>
  );
}

export default Table;

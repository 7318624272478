import './Table.css';
import { contractNames } from '../../config/contracts/types';
import { BlockchainRow } from './BlockchainRow';
import { useGetBlockchains } from '../../contexts/GetBlockchains';
import { HighlightedStringContext, THighlightedStringContext } from '../../contexts/GetHighlightedString';
import { useState } from 'react';

function Table() {
  const blockchains = useGetBlockchains();
  const [highlightedString, setHighlightedString] = useState<string | undefined>()

  return (
    <table className='table table-bordered table-hover border-primary table-responsive-sm contracts-table'>
        <thead>
            <tr>
                <th>Network</th>
                { contractNames.map((contract, key) => <th key={key}>{contract}</th>) }
            </tr>
        </thead>
        <tbody>
            <HighlightedStringContext.Provider value={{highlightedString, setHighlightedString}}>
              {blockchains.map((blockchain, key) =>
                    <BlockchainRow blockchain={blockchain} key={key} />
                )}
            </HighlightedStringContext.Provider>
        </tbody>
    </table>
  );
}

export default Table;

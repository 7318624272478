import './App.css';
import Table from './routes/Dashboard/Table';
import { useState } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Dashboard } from './routes/Dashboard/Dashboard';
import { CheckRouters } from './routes/CheckRouters/CheckRouters';
import { getBlockchains } from './Blockchains';
import { BlockchainsContext, TBlockchainsContext } from './contexts/GetBlockchains';
import { DeBridgeGateChannels } from './routes/DeBridgeGateChannels/DeBridgeGateChannels';
import { DLNChannels } from './routes/DLNChannels/DLNChannels';
import { Summary } from './routes/Summary/Summary';
import { Fees } from './routes/Fees/Fees';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Summary/>,
  },
  {
    path: "/dashboard",
    element: <Dashboard/>,
  },
  {
    path: "/fees",
    element: <Fees/>,
  },
  {
    path: "/debridge-gate-channels",
    element: <DeBridgeGateChannels/>,
  },
  {
    path: "/dln-channels",
    element: <DLNChannels/>,
  },
  {
    path: "/check-routers",
    element: <CheckRouters/>
  }
]);

function App() {
  const [blockchains] = useState<TBlockchainsContext>(getBlockchains())

  return (
  <BlockchainsContext.Provider value={blockchains}>
    <RouterProvider router={router} />
  </BlockchainsContext.Provider>
  );
}

export default App;

import { IDebridgeGateAbi } from "../../utils/Contracts/abis/IDeBridgeGate.abi";
import { ContractName, ContractTypeMap, TBaseContractName } from "./types";
import { DlnDestinationAbi } from "../../utils/Contracts/abis/DlnDestination.abi";
import { TBlockchain } from "../../Blockchains";
import { DeBridgeRouterAbi } from "../../utils/Contracts/abis/DeBridgeRouter.abi";
import { CallProxyAbi } from "../../utils/Contracts/abis/CallProxy.abi";
import { DlnSourceAbi } from "../../utils/Contracts/abis/DlnSource.abi";
import { ExternalCallAdapterAbi } from "../../utils/Contracts/abis/ExternalCallAdapter.abi";
import { DownscaledTokenFactoryAbi } from "../../utils/Contracts/abis/DownscaledTokenFactory.abi";
import { ClaimerAbi } from "../../utils/Contracts/abis/Claimer.abi";
import { SignatureVerifierAbi } from "../../utils/Contracts/abis/SignatureVerifier.abi";

export type Contract<T extends ContractName> = {
    contractName: T;
    address: string;
    contract: ContractTypeMap[T]
}

export type TContractSet = Map<ContractName, Contract<any> | undefined>
export type TBaseContractSet = Map<TBaseContractName, Contract<any> | undefined>

function contract<T extends ContractName>(name: T, contract: ContractTypeMap[T]): Contract<T> {
    return {
        contractName: name,
        address: contract.options.address!,
        contract: contract
    }
}

export function getBaseContractSet(chain: TBlockchain): TBaseContractSet {
    const map: TBaseContractSet = new Map();

    const deBridgeGate = new chain.web3.eth.Contract(IDebridgeGateAbi, chain.baseContracts[ContractName.DeBridgeGate]);
    map.set(ContractName.DeBridgeGate, contract<ContractName.DeBridgeGate>(ContractName.DeBridgeGate, deBridgeGate))

    map.set(ContractName.Claimer, contract<ContractName.Claimer>(ContractName.Claimer,
        new chain.web3.eth.Contract(ClaimerAbi, chain.baseContracts[ContractName.Claimer])
    ))

    map.set(ContractName.DlnSource, contract<ContractName.DlnSource>(ContractName.DlnSource, new chain.web3.eth.Contract(DlnSourceAbi, chain.baseContracts[ContractName.DlnSource])))
    const dlnDestination = new chain.web3.eth.Contract(DlnDestinationAbi, chain.baseContracts[ContractName.DlnDestination])
    map.set(ContractName.DlnDestination, contract<ContractName.DlnDestination>(ContractName.DlnDestination, dlnDestination))

    map.set(ContractName.DeBridgeRouter, contract<ContractName.DeBridgeRouter>(ContractName.DeBridgeRouter, new chain.web3.eth.Contract(DeBridgeRouterAbi, chain.baseContracts[ContractName.DeBridgeRouter])))

    const downscaledTokenFactoryAddress = chain.baseContracts[ContractName.DownscaledTokenFactory]
    if (downscaledTokenFactoryAddress)
        map.set(ContractName.DownscaledTokenFactory, contract<ContractName.DownscaledTokenFactory>(ContractName.DownscaledTokenFactory, new chain.web3.eth.Contract(DownscaledTokenFactoryAbi, downscaledTokenFactoryAddress)))
    return map
}

export async function getContracts(chain: TBlockchain): Promise<TContractSet> {
    const map: TContractSet = new Map();

    if (chain.baseContracts[ContractName.Claimer]) {
        map.set(ContractName.Claimer, contract<ContractName.Claimer>(ContractName.Claimer,
            new chain.web3.eth.Contract(ClaimerAbi, chain.baseContracts[ContractName.Claimer])
        ))
    }

    if (chain.baseContracts[ContractName.DeBridgeGate]) {
        const deBridgeGate = new chain.web3.eth.Contract(IDebridgeGateAbi, chain.baseContracts[ContractName.DeBridgeGate]);
        map.set(ContractName.DeBridgeGate, contract<ContractName.DeBridgeGate>(ContractName.DeBridgeGate, deBridgeGate))

        map.set(ContractName.CallProxy, contract<ContractName.CallProxy>(ContractName.CallProxy, new chain.web3.eth.Contract(CallProxyAbi, await deBridgeGate.methods.callProxy().call().catch(() => undefined))));
        map.set(ContractName.SignatureVerifier, contract<ContractName.SignatureVerifier>(ContractName.SignatureVerifier, new chain.web3.eth.Contract(SignatureVerifierAbi, await deBridgeGate.methods.signatureVerifier().call().catch(() => undefined))));
    }

    if (chain.baseContracts[ContractName.DlnSource])
        map.set(ContractName.DlnSource, contract<ContractName.DlnSource>(ContractName.DlnSource, new chain.web3.eth.Contract(DlnSourceAbi, chain.baseContracts[ContractName.DlnSource])))

    if (chain.baseContracts[ContractName.DlnDestination]) {
        const dlnDestination = new chain.web3.eth.Contract(DlnDestinationAbi, chain.baseContracts[ContractName.DlnDestination])
        map.set(ContractName.DlnDestination, contract<ContractName.DlnDestination>(ContractName.DlnDestination, dlnDestination))
        map.set(ContractName.ExternalCallAdapter, contract<ContractName.ExternalCallAdapter>(ContractName.ExternalCallAdapter, new chain.web3.eth.Contract(ExternalCallAdapterAbi, await dlnDestination.methods.externalCallAdapter().call().catch(() => undefined))))
    }

    if (chain.baseContracts[ContractName.DeBridgeRouter])
        map.set(ContractName.DeBridgeRouter, contract<ContractName.DeBridgeRouter>(ContractName.DeBridgeRouter, new chain.web3.eth.Contract(DeBridgeRouterAbi, chain.baseContracts[ContractName.DeBridgeRouter])))

    if (chain.baseContracts[ContractName.DownscaledTokenFactory])
        map.set(ContractName.DownscaledTokenFactory, contract<ContractName.DownscaledTokenFactory>(ContractName.DownscaledTokenFactory, new chain.web3.eth.Contract(DownscaledTokenFactoryAbi, chain.baseContracts[ContractName.DownscaledTokenFactory])))

    return map
}
import React, { useEffect, useState } from 'react';
import './Table.css';
import { TBlockchain } from '../../Blockchains';
import { ContractName, contractNames, ContractTypeMap } from '../../config/contracts/types';
import { getBaseContractSet, getContracts, TContractSet } from '../../config/contracts/contractSet';
import Bottleneck from 'bottleneck';

type TPrice = number | undefined | "error"

// TODO move to a separate context provider
const limiter = new Bottleneck({
    maxConcurrent: 10,     // Allow 10 requests to run concurrently
    reservoir: 10,         // Start with 10 request slots
    reservoirRefreshAmount: 10, // Refill 10 slots at once
    reservoirRefreshInterval: 11000, // Wait 11s between batch refills
});

function fetchPrice(id: number) {
    return limiter.schedule(() => {
        return fetch(`https://deswap.debridge.finance/api/v1.0/token-price?chainId=${id}&tokenAddress=0x0000000000000000000000000000000000000000&accesstoken=ETJGoIN20pCM`)
            .then((res) => res.ok ? res.json() : Promise.reject(res.status))
            // .then((res) => res.json())
            .then((data) => data.usdPrice)
    });
}

function PrintFee({ blockchain, fee, price }: { blockchain: TBlockchain, fee: bigint, price: TPrice }) {
    const hFee = ( Number(fee / 10n ** 10n) / 10 ** 8)

    if (typeof price != 'number')
        return <td>{hFee} {blockchain.symbol} ({ price === 'error' ? "💔" : "👀"})</td>;

    const hPrice = Number(hFee * price)
    return  <td
      style={{
        backgroundColor: (() => {
          if (hPrice > 1) {
            return `rgba(0, 128, 0, ${Math.min(Math.log(hPrice) / 5, 0.5)})`;
          } else if (hPrice < 1) {
            return `rgba(255, 0, 0, ${Math.min(Math.log(1 / hPrice) / 5, 0.5)})`;
          }
          return undefined;
        })()
      }}
    >{hFee} {blockchain.symbol} (${hPrice.toFixed(2)})
    </td>;
}

export function BlockchainRow({ blockchain }: { blockchain: TBlockchain }) {
    const [price, setPrice] = useState<number | undefined | "error">()
    const [fees, setFees] = useState<{ fixFee1: bigint, transferFee1: bigint, fixFee2: bigint, transferFee2: bigint } | undefined>()

    // Separate fetch initialization from render cycle
    useEffect(() => {
        // Skip if we already have a price
        if (price !== undefined) return;

        // Use setTimeout to decouple from React's render cycle
        const timer = setTimeout(() => {
            fetchPrice(blockchain.internalId)
                .then((usdPrice) => {
                    console.log("Price fetched:", usdPrice);
                    setPrice(usdPrice);
                })
                .catch((err) => {
                    console.error("Error fetching price:", err);
                    setPrice('error');
                });
        }, 0);

        return () => clearTimeout(timer);
    }, [blockchain.internalId, price]); // Remove priceFetching dependency

    // Similar approach for fees
    useEffect(() => {
        if (fees !== undefined) return;

        const timer = setTimeout(() => {
            const cs = getBaseContractSet(blockchain);
            const deBridgeGate = cs.get(ContractName.DeBridgeGate)!.contract;
            const dlnSource = cs.get(ContractName.DlnSource)!.contract;

            Promise.all([
                deBridgeGate.methods.globalFixedNativeFee().call(),
                deBridgeGate.methods.globalTransferFeeBps().call(),
                dlnSource.methods.globalFixedNativeFee().call(),
                dlnSource.methods.globalTransferFeeBps().call(),
            ]).then(([fixFee1, transferFee1, fixFee2, transferFee2]) => {
                setFees({
                    fixFee1: BigInt(fixFee1),
                    transferFee1: BigInt(transferFee1),
                    fixFee2: BigInt(fixFee2),
                    transferFee2: BigInt(transferFee2)
                });
            }).catch(err => {
                console.error("Error fetching fees:", err);
            });
        }, 0);

        return () => clearTimeout(timer);
    }, [blockchain.internalId, fees]);

    // Rest of your component remains the same
    return (
        <tr>
            <td>{blockchain.name}</td>
            {fees ? <PrintFee fee={fees.fixFee1} blockchain={blockchain} price={price} /> : '👀'}
            <td>{fees ? `${fees.transferFee1}bps` : '👀'}</td>
            {fees ? <PrintFee fee={fees.fixFee2} blockchain={blockchain} price={price} /> : '👀'}
            <td>{fees ? `${fees.transferFee2}bps` : '👀'}</td>
        </tr>
    );
}
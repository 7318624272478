import Web3, { Web3APIPayload } from "web3"
import { BlockchainsConfig } from "./config/blockchains";
import { TBaseContractsConfig } from "./config/contracts/types";
import Bottleneck from "bottleneck"
import { getBaseContractSet, TBaseContractSet } from "./config/contracts/contractSet";

export type TBlockchain = {
    name: string;
    symbol: string;
    chainId: number;
    internalId: number;
    web3: Web3;
    explorer: Explorer;
    baseContracts: TBaseContractsConfig,
}

class Explorer {
    constructor(private readonly baseUrl: string) {}
    tx(txHash: string) {
        return this.baseUrl.replace('%s', `tx/${txHash}`)
    }
    addr(address: string) {
        return this.baseUrl.replace('%s', `address/${address}`)
    }
    link() {
        return this.baseUrl.replace('%s', '')
    }
}

function getCachedWeb3(rpc: string) {
    const limiter = new Bottleneck({
        maxConcurrent: 4, // Maximum number of concurrent requests
        minTime: 100,     // Minimum delay between requests (ms)
      });

    const provider = new Web3.providers.HttpProvider(rpc);

    // Save the original request, send, and sendAsync methods
    const originalRequest = provider.request!
        ? provider.request.bind(provider)
        : null;

    const cache: {[key in string]: any} = {};

    // Helper function to throttle requests
    const throttleRequest = async (sendMethod: Function, payload: Web3APIPayload<any,any>) => {
        const key = JSON.stringify([payload.method, payload.params]);
        if (cache[key]) {
            console.log("🔴 CACHE", payload)
            return cache[key];
        }

        const v = limiter.schedule(() => {
            return sendMethod(payload);
        });
        cache[key] = v;
        return v;
    };

    // Override the request method (modern Web3.js)
    if (originalRequest) {
        provider.request = async function (payload: any) {
            return throttleRequest(originalRequest, payload);
        } as any;
    }

    return new Web3(provider)
}

export function getBlockchains(): TBlockchain[] {

    return BlockchainsConfig.map(blockchain => {
        return {
            ...blockchain,
            internalId: blockchain.subscriptionId || blockchain.chainId,
            explorer: new Explorer(blockchain.explorerBaseUrl),
            web3: getCachedWeb3(blockchain.rpc),
        }
    })
}

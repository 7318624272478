import React, { useEffect, useState } from 'react';
import './Table.css';
import { TBlockchain } from '../../Blockchains';
import { ContractName, contractNames } from '../../config/contracts/types';
import { getContracts, TContractSet } from '../../config/contracts/contractSet';
import { HightlistedString } from '../Design/HightlightedString';

export function BlockchainRow({ blockchain }: { blockchain: TBlockchain }) {
    const [contractSet, setContractSet] = useState<TContractSet | undefined>()

    useEffect(() => {
        if (!contractSet)
            getContracts(blockchain)
                .then(v => setContractSet(v))
    }, [contractNames])

    return (<tr>
        <td><a href={blockchain.explorer.link()}>{blockchain.name}</a> <div><small>{blockchain.chainId}</small></div></td>
        {contractNames.map((contractName, key) => {
            const contract = contractSet?.get(ContractName[contractName])
            if (!contractSet) {
                return <td>👀</td>
            }
            if (!contract?.address) {
                return <td key={key}>N/A</td>
            }
            else {
                return (
                    <td>
                        <div><a href={blockchain.explorer.addr(contract.address)} target='_blank'>
                            <HightlistedString s={contract.address.slice(0, 8)}/>...
                        </a></div>
                    </td>
                )
            }
        }
        )}
    </tr>)
}